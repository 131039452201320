import React, { useState } from 'react';
import BackgroundImage from '../../components/BackgroundImage/BackgroundImage';
import Header from '../../components/Header/Header';
import ImageContainer from '../../components/ImageContainer/ImageContainer';
import Button from '../../components/Buttons/Button'; // Import the Button component
import { backgrounds } from '../../data/backgrounds';
import Modal from '../../components/Modal/Modal';
import AboutModal from '../../components/AboutModal/AboutModal';
import ReportModal from '../../components/ReportModal/ReportModal';

const Invite = ({ post, setPageProgress }) => {
    const backgroundImageUrl = post?.image;
    const [showModal, setShowModal] = useState(false);
    const [modal, setModal] = useState('');
    const [menuOpen, setMenuOpen] = useState(false);

    const onCheckoutClick = (checkoutValue) => {
        setPageProgress('consent', checkoutValue);
    }

    const handleModal = (modal) => {
        setShowModal(true);
        setMenuOpen(false);
        setModal(modal);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModal('');
    };


    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', height: '100vh' }}>
            <BackgroundImage imageUrl={backgrounds['checkout']} />

            <div style={{ display: 'flex', alignSelf: 'center',justifyContent: 'center', position: 'relative', margin: 20, height: '65vh', width: '85vw', background: 'linear-gradient(to bottom, rgb(255, 37, 103) 0%, rgb(252, 112, 0) 81%)' ,alignItems: 'center', borderRadius: 20, 
             }}>
                <ImageContainer imageUrl={backgroundImageUrl}>

                <div style={{
                        position: 'absolute',
                        top: 10, left: 10,
                        padding: '10px',
                        cursor: 'pointer',
                        backgroundColor: '#FFF',
                        color: '#000',
                        borderRadius: '20px',
                         fontWeight: '700',
                         display: 'flex',
                         alignItems: 'center',
                         fontSize: '0.8em'
                    }}
                    onClick={() => handleModal('about')}
                    >
                        Just for Fun 😉
                    </div>


                    {/* captions */}
                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            padding: '10px',
                            color: '#FFF',
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                            borderRadius: '0 20px',
                            padding: '30px',
                            fontWeight: 'bolder',
                            width: '100%' // Ensure text takes 100% width
                        }}
                    >
                        <h3 style={{ fontWeight: 'bolder', fontSize: '1.7em', marginBottom: '5px' }}>
                            👻 Ta-da! {/* Hero emoji with sunglass */}
                        </h3>
                        <h3 style={{ fontWeight: 'bolder', fontSize: '1.7em', marginBottom: '5px' }}>
                            <span style={{ color: '#FFE500' }}>{post?.createdBy.firstName}</span> posted this
                        </h3>
                        <h3 style={{ fontWeight: 'bolder', fontSize: '1.7em', marginBottom: '5px' }}>
                           pic to see who has
                        </h3>
                        <h3 style={{ fontWeight: 'bolder', fontSize: '1.7em' }}>
                            <span style={{ color: '#FFE500' }}>crush on you</span>! 🥰
                        </h3>
                    </div>

                </ImageContainer>
            </div>

            {/* Button component */}
            <div
                style={{
                    backgroundColor: 'white', color: 'black', width: '80%', margin: '20px auto', borderRadius: '50px', padding: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center',

                    fontWeight: 'bold', // Set text to be bolder
                    textAlign: 'center', // Center-align the text
                    cursor: 'pointer' // Show pointer cursor on hover
                }}
                onClick={() => onCheckoutClick('consent')}
            >
                See Next
            </div>

            <Modal show={showModal} handleClose={handleCloseModal}>
                {modal === 'about' ? (
                    <AboutModal />
                ) : null}
                {modal === 'report' ? (
                    <ReportModal />
                ) : null}
            </Modal>
        </div>
    )
}

export default Invite;
