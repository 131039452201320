import React from 'react';
import './Contact.css'; // Custom CSS for styles

const ContactUs = () => {
    return (
        <div className="contact-container">
            <h1 className="contact-title">Contact Us</h1>
            
            <div className="contact-cards">
                <div className="contact-card">
                    <h2 className="card-title">General Inquiry</h2>
                    <p className="card-description">To contact the <span className="highlight">"Checkout My Friend"</span> Team, reach out to us at:</p>
                    <a href="mailto:contact@checkoutmyfriend.link" className="contact-link">contact@checkoutmyfriend.link</a>
                </div>

                <div className="contact-card">
                    <h2 className="card-title">Help</h2>
                    <p className="card-description">To contact the <span className="highlight">"Checkout My Friend"</span> Help Team, reach out to us at:</p>
                    <a href="mailto:help@checkoutmyfriend.link" className="contact-link">help@checkoutmyfriend.link</a>
                </div>

                <div className="contact-card">
                    <h2 className="card-title">Legal Requests</h2>
                    <p className="card-description">Information requesters, such as law enforcement or legal authorities, should reach out to:</p>
                    <a href="mailto:legal@checkoutmyfriend.link" className="contact-link">legal@checkoutmyfriend.link</a>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
