// src/services/axios.js

import axios from 'axios';

// Create an Axios instance with default settings
const axiosInstance = axios.create({
    baseURL: 'https://api.checkoutmyfriend.link/v1/api/',
    // baseURL: 'http://localhost:80/v1/api/',
    headers: {
        'Content-Type': 'application/json',
    },
});

export default axiosInstance;
