import React from 'react';
import { CircularProgress } from '@mui/material';

const GenericLoadingModal = ({ isVisible, onClose }) => {
    if (!isVisible) return null;

    return (
        <div style={styles.overlay}>
            <div style={styles.modal}>
                <CircularProgress size={50} style={{ color: '#FF3148' }} />
                <p style={styles.text}>Loading...</p>
                <button onClick={onClose} style={styles.closeButton}>
                    Close
                </button>
            </div>
        </div>
    );
};

const styles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    modal: {
        backgroundColor: '#FFF',
        padding: '20px',
        borderRadius: '10px',
        textAlign: 'center',
    },
    text: {
        marginTop: '10px',
        fontSize: '16px',
        color: '#000',
    },
    closeButton: {
        marginTop: '20px',
        padding: '10px 20px',
        backgroundColor: '#FF3148',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
};

export default GenericLoadingModal;
