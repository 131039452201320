import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Landing from './pages/Landing/Landing';
import CheckoutProfile from './pages/CheckoutProfile/CheckoutProfile';
import UserProvider from './providers/UserProvider';
import CheckoutInvite from './pages/CheckoutInvite/CheckoutInvite';
import Error from './pages/Error/Error';
import Tos from './pages/TOS/Tos';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import FAQPage from './pages/Faq/Faq';
import CommunityGuidelines from './pages/CommunityGuidelines/CommunityGuidelines';
import SafetyCenter from './pages/SafetyCenter/SafetyCenter';
import About from './pages/About/About';
import DeleteAccount from './pages/DeleteAccount/DeleteAccount';
import RequestData from './pages/RequestData/RequestData';
import Report from './pages/Report/Report';
import ContactUs from './pages/Contact/Contact';

function App() {
  return (
    <UserProvider>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path='/error' element={<Error />} />
            <Route path='/tos' element={<Tos />} />
            <Route path='/delete-account' element={<DeleteAccount />} />
            <Route path='/request-data' element={<RequestData />} />
            <Route path='/report' element={<Report />} />
            <Route path='/contact-us' element={<ContactUs />} />
            <Route path='/Faq' element={<FAQPage />} />
            <Route path='/about' element={<About />} />
            <Route path='/safetycenter' element={<SafetyCenter />} />
            <Route path='/communityguidelines' element={<CommunityGuidelines />} />
            <Route path='/privacy' element={<PrivacyPolicy />} />
            <Route path="invite/:id" element={<CheckoutInvite />} />
            <Route path="/:id" element={<CheckoutProfile />} />
          </Routes>
        </Router>
      </div>
    </UserProvider>
  );
}

export default App;
