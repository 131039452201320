import React, { useContext, useEffect, useRef, useState } from 'react';
import BackgroundImage from '../../components/BackgroundImage/BackgroundImage';
import CircularImage from '../../components/CircularImage/CircularImage';
import './Exit.css';
import { UserContext } from '../../providers/UserProvider';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../services/axios';
import { backgrounds } from '../../data/backgrounds';
import { iconsData } from '../../data/icons';
import AboutModal from '../../components/AboutModal/AboutModal';
import ExitModal from '../../components/ExitModal/ExitModal';
import InstagramModal from '../../components/InstagramModal/AboutModal';
import { FaArrowLeft, FaBars, FaTimes } from 'react-icons/fa';  // Import the left arrow icon
import Modal from '../../components/Modal/Modal';
import ReportModal from '../../components/ReportModal/ReportModal';

const Exit = ({ post, whatValue, type }) => {
    const { user } = useContext(UserContext);
    const { id } = useParams();
    const [compliment, setCompliment] = useState(null);
    const [instagramModal, setInstagramModal] = useState(true);
    const [instagramConnect, setInstagramConnect] = useState(false);
    const [instagramUsername, setInstagramUsername] = useState('');
    const [menuOpen, setMenuOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modal, setModal] = useState('');
    const navigate = useNavigate();

    const toggleMenu = () => {
        console.log('clicked')
        setMenuOpen(!menuOpen);
    };

    const handleModal = (modal) => {
        if (modal !== 'privacy' && modal !== 'tos') {
            setShowModal(true);
            setMenuOpen(false);
            setModal(modal);
        } else {
            navigate(`/${modal}`);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModal('');
    };


    const handleOpenInstagramModal = () => {
        setInstagramModal(true);
    };

    const handleCloseInstagramModal = () => {
        setInstagramModal(false);
    };

    const handleInstagramConnect = () => {
        setInstagramConnect(true);
        setInstagramModal(false);
    };

    const backgroundImageUrl = post?.image;
    const logo = 'https://i.ibb.co/yyjy9t4/checkout-my-Friend.png';

    useEffect(() => {
        const sendCompliment = async () => {
            if (user && id && whatValue && type) {
                let reqObj;

                if (type === 'prank') {
                    reqObj = {
                        postId: id,
                        message: whatValue._id,
                        complimentType: whatValue.type,
                        prank: true,
                    };
                } else {
                    reqObj = {
                        postId: id,
                        message: whatValue,
                        complimentType: type,
                    };
                }

                try {
                    const response = await axiosInstance.post('/compliments', reqObj, {
                        headers: {
                            Authorization: `Bearer ${user.token}`,
                        },
                    });
                    setCompliment(response.data);
                } catch (error) {
                    console.error('Error sending compliment:', error);
                }
            }
        };

        sendCompliment();
    }, [user, id, whatValue, type]);


    const inputRef = useRef(null);

    useEffect(() => {
        // Automatically focus the input field when the component mounts
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);


    const handleInstagramConnectBtn = async () => {
        if (instagramUsername.length >= 5) {
            await addInstagram();
            setInstagramConnect(false);
        } else {
            setInstagramConnect(false);
        }
    }


    const calculateLikePercentage = () => {
        const date = post?.scores?.date;
        const marry = post?.scores?.marry;
        const hookup = post?.scores?.hookup;
        const total = date + marry + hookup;

        return {
            date: Math.round((date/total) * 100) || 0,
            marry: Math.round((marry/total) * 100) || 0,
            hookup: Math.round((hookup/total) * 100) || 0 
        }
    }


    const getTag = () => {

        const percentages = calculateLikePercentage();

        if(percentages.date > percentages.marry && percentages.date > percentages.hookup) {
            return 'Crush Material';
        }
        else if(percentages.marry > percentages.date && percentages.marry > percentages.hookup) {
            return 'Fun Person';
        }
        else if(percentages.hookup > percentages.date && percentages.hookup > percentages.marry) {
            return 'Rizzler';
        }
        else {
            return 'GOAT Person'
        }
        
    }


    const addInstagram = async () => {
        try {
            const response = await axiosInstance.post('/users/update-instagram', {instagram: instagramUsername}, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
        } catch (error) {
            console.error('Error sending instagram:', error);
        }
    }

  
    return (
        <>
            <BackgroundImage imageUrl={backgrounds[type]} />

            <div
                onClick={toggleMenu}
                style={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    padding: '10px',
                    cursor: 'pointer',
                    zIndex: 1000
                }}
            >
                {menuOpen ? <FaTimes size={24} color="#FFF" /> : <FaBars size={24} color="#FFF" />}
            </div>

            {menuOpen && (
                <div
                    style={{
                        position: 'absolute',
                        top: 50,
                        right: 10,
                        backgroundColor: '#000',
                        borderRadius: '20px',
                        padding: '30px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        zIndex: 120,
                    }}
                >
                    <div style={{ width: '100%', borderBottom: '1px solid grey', paddingBottom: '10px', marginBottom: '5px', textAlign: 'center' }} onClick={() => handleModal('about')}>
                        <span style={{ color: '#FFF', fontWeight: 'bold', cursor: 'pointer', fontSize: '1.5em' }}>About</span>
                    </div>
                    <div style={{ width: '100%', borderBottom: '1px solid grey', paddingBottom: '10px', marginBottom: '5px', textAlign: 'center' }} onClick={() => handleModal('privacy')}>
                        <span style={{ color: '#FFF', fontWeight: 'bold', cursor: 'pointer', fontSize: '1.5em' }}>Privacy</span>
                    </div>

                    <div style={{ width: '100%', borderBottom: '1px solid grey', paddingBottom: '10px', marginBottom: '5px', textAlign: 'center' }} onClick={() => handleModal('tos')}>
                        <span style={{ color: '#FFF', fontWeight: 'bold', cursor: 'pointer', fontSize: '1.5em' }}>Terms</span>
                    </div>
                    <div style={{ width: '100%', textAlign: 'center' }} onClick={() => handleModal('report')}>
                        <span style={{ color: 'red', fontWeight: 'bold', cursor: 'pointer', fontSize: '1.5em' }}>Report</span>
                    </div>
                </div>
            )}

            {instagramConnect ? (
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100vh' }}>
                    {/* Back Icon */}
                    <div
                        onClick={() => setInstagramConnect(false)}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: 15,
                            backgroundColor: 'rgba(255, 255, 255, 0.36)', // 36% opacity of white
                            borderRadius: 20,
                            width: 20,
                            height: 20,
                            padding: 10
                        }}
                    >
                        <FaArrowLeft style={{ color: '#ffffff' }} /> {/* Left arrow with 36% opacity */}
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column', flex: 1, marginTop: 30 }}>
                        {/* Add Instagram */}
                        <p style={{ fontWeight: 800, fontSize: '2.5rem', color: '#fff' }}>Add Instagram</p>

                        {/* To Connect Personally */}
                        <p style={{ fontWeight: 600, fontSize: '1.2rem', color: '#fff' }}>To connect personally 🤞</p>

                        {/* Input Field */}
                        <input
                            type="text"
                            placeholder="@Username"
                            value={instagramUsername}
                            ref={inputRef}
                            className="custom-input"
                            onChange={(e) => setInstagramUsername(e.target.value)}
                            style={{
                                borderRadius: 10,
                                backgroundColor: 'rgba(255, 255, 255, 0.333)',
                                width: '75%',
                                padding: '20px 25px',
                                fontSize: '1rem',
                                border: 'none',
                                marginTop: 30
                            }}
                        />

                        {/* Button Logic */}
                        <button
                            onClick={handleInstagramConnectBtn}
                            style={{
                                marginTop: 20,
                                padding: '20px 50px',
                                borderRadius: 30,
                                backgroundColor: instagramUsername.length >= 5 ? '#000000' : 'rgba(0, 0, 0, 0.32)',
                                color: '#ffffff',
                                border: 'none',
                                fontWeight: 600,
                                cursor: 'pointer',
                            }}
                        >
                            {instagramUsername.length >= 5 ? 'Done' : 'Cancel'}
                        </button>
                    </div>
                </div>
            ) : (
                <div className="exit-container">
                    <CircularImage backgroundImageUrl={backgroundImageUrl} icon={iconsData[type]} />

                    {type === 'prank' ? (
                        <>
                            <p style={{ color: 'white', fontWeight: '900', fontSize: '2em', marginTop: '10px', marginBottom: '10px' }}>
                                Prank Sent! ✅
                            </p>
                            <p style={{ color: 'white', fontWeight: '700', fontSize: '1.2em' }}>"{whatValue?.description}"</p>
                        </>
                    ) : (
                        <>
                            <p className="exit-message" style={{marginTop: 10, marginBottom: 10, fontStyle:'italic', marginBottom: 20}}>
                                {compliment ? `${getTag()}` : 'Loading...'}
                            </p>

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',  marginBottom: 20}}>

                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 10}}>
                                    <img src={require('../../assets/icons/Date.png')} style={{width: 30, height:  30, border: '1px solid white', borderRadius: 50}} />
                                    <div
                                        style={{
                                            background: 'linear-gradient(to right, transparent, rgba(255, 255, 255, 0.45))',
                                            padding: 7,
                                            paddingLeft: 15,
                                            paddingRight: 15,
                                            borderRadius: 10,
                                            color: 'white',
                                            fontWeight: '700'
                                        }}
                                    >
                                        {calculateLikePercentage().date}%
                                    </div>
                                </div>


                                   <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 10}}>
                                <img src={require('../../assets/icons/Chill.png')} style={{width: 30, height:  30, border: '1px solid white', borderRadius: 50}} />
                                    <div
                                        style={{
                                            background: 'linear-gradient(to right, transparent, rgba(255, 255, 255, 0.45))',
                                            padding: 7,
                                            paddingLeft: 15,
                                            paddingRight: 15,
                                            borderRadius: 10,
                                            color: 'white',
                                            fontWeight: '700'
                                        }}
                                    >
                                         {calculateLikePercentage().marry}%
                                    </div>
                                </div>




                                   <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 10}}>
                                   <img src={require('../../assets/icons/Hookup.png')} style={{width: 30, height:  30, border: '1px solid white', borderRadius: 50}} />
                                    <div
                                        style={{
                                            background: 'linear-gradient(to right, transparent, rgba(255, 255, 255, 0.45))',
                                            padding: 7,
                                            paddingLeft: 15,
                                            paddingRight: 15,
                                            borderRadius: 10,
                                            color: 'white',
                                            fontWeight: '700'
                                        }}
                                    >
                                         {calculateLikePercentage().hookup}%
                                    </div>
                                </div>



                            </div>
                            <img src={iconsData['exitLoader']} style={{ width: '60vw' , marginBottom: 50}} />
                        </>
                    )}

                    <p className="exit-message">
                        <span className="highlight">Want</span> to see who  likes your friend?
                    </p>

                    <img src={iconsData['findOut']} style={{ width: '90%' }} />
                    <img src={iconsData['arrowGolden']} style={{ width: '60vw', marginTop: '20px' }} />
                    <img src={iconsData['cmfWhite']} style={{ width: '60vw', position: 'absolute', bottom: -50 }} />
                </div>
            )}

            <ExitModal show={instagramModal} handleClose={handleCloseInstagramModal} handleInstagramConnect={handleInstagramConnect}>
                <InstagramModal photo={backgroundImageUrl} type={type} />
            </ExitModal>

            <Modal show={showModal} handleClose={handleCloseModal}>
                {modal === 'about' ? (
                    <AboutModal />
                ) : null}
                {modal === 'report' ? (
                    <ReportModal />
                ) : null}
            </Modal>
        </>
    );
};

export default Exit;
