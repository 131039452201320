import React, { useState, useEffect, createContext } from "react";
import { auth, googleProvider, logOut, signInWithGoogle } from "../services/Firebase";
export const UserContext = createContext({ user: null });
export default (props) => {
    const [user, setuser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        auth.onAuthStateChanged(async (user) => {
            if (user) {
                const token = await user.getIdToken();
                console.log('id token', token);
                const { displayName, email } = user;
                setuser({
                    displayName,
                    email,
                    token
                });
                
            }
            setLoading(false);
        });
    }, []);



    
    return (
        <UserContext.Provider value={{user: user, loading: loading}}>{props.children}</UserContext.Provider>
    );
};