import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router-dom for navigation
import axiosInstance from '../../services/axios';
import GeneralLoadingModal from '../../components/GenericModal/GenericModal'; // Import the generic loader modal
import LoginUserPage from '../LoginUserPage/LoginUserPage';
import { UserContext } from '../../providers/UserProvider';

const Report = () => {
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [isLoadingModalVisible, setIsLoadingModalVisible] = useState(false);
    const [success, setSuccess] = useState('');
    const { user } = useContext(UserContext);

    useEffect(() => {
        if (loading) {
            setIsLoadingModalVisible(true);
        } else {
            setIsLoadingModalVisible(false);
        }
    }, [loading]);

    const requestData = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.post('/request/web', { data: message, type: 'report' }, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            setSuccess(`Thanks for writing! We'll write back to your registered email address: ${user.email}`);
        } catch (error) {
            setSuccess(`The request could not be completed at this time. This may be due to the absence of an account associated with the currently signed-in email, a ban from the platform, or another issue. You can try again later or reach out to us at help@checkoutmyfriend.link for assistance.`);
            console.error('Error:', error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
       <>
        {user ? ( <div style={styles.container}>
            <h2 style={styles.headerText}>Report</h2>

            {success ? (
                <div style={{ padding: '20px', marginTop: '20px', marginBottom: '50px' }}>
                    <p style={{ color: '#A0A0A0', lineHeight: '25px', fontSize: '15px' }}>
                        {success}
                    </p>
                </div>
            ) : (
                <div style={{ padding: '20px', marginTop: '20px', marginBottom: '50px' }}>
                    <h3 style={{ fontSize: '25px', color: 'white', fontWeight: '700' }}>Description</h3>

                    <textarea
                        style={styles.textInput}
                        placeholder="Type your message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        rows={5}
                    />

                    <p style={{ color: '#A0A0A0', lineHeight: '25px', fontSize: '14px' }}>
                        Please enter the details of your request and we will respond as soon as possible.
                    </p>
                </div>
            )}

            <div style={{ width: '100%', alignItems: 'center', flexDirection: 'column', }}>
                {success ? (
                    <button
                        disabled={loading || message.length < 2}
                        onClick={() => navigate(-1)}
                        style={{ ...styles.button, backgroundColor: '#FFFFFF' }}>
                        Got it!
                    </button>
                ) : (
                    <div style={{flexDirection: 'column', alignItems: 'center',   margin: 20,}}>
                        <button
                            disabled={loading || message.length < 2}
                            onClick={requestData}
                            style={{ ...styles.button, backgroundColor: message.length < 2 ? '#5D5D5D' : '#FFFFFF' }}>
                            Submit
                        </button>

                        <button
                            onClick={() => navigate(-1)}
                            style={{ ...styles.button, backgroundColor: '#000000', color: '#FFFFFF' }}>
                            Cancel
                        </button>
                    </div>
                )}
            </div>

            {isLoadingModalVisible && <GeneralLoadingModal isVisible={isLoadingModalVisible} />}
        </div>) : (<LoginUserPage />)}
       </>
    );
};

const styles = {
    container: {
        padding: '10px',
        backgroundColor: '#000',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
    },
    textInput: {
        width: '90%',
        backgroundColor: '#181818',
        borderRadius: '20px',
        color: 'white',
        padding: '20px',
        border: '0.5px solid #909090',
        fontSize: '18px',
        marginTop: '20px',
        marginBottom: '20px',
    },
    headerText: {
        fontSize: '2rem',
        fontWeight: '900',
    },
    button: {
        padding: '20px',
        borderRadius: '30px',
        width: '98%',
        marginBottom: '20px',
        fontWeight: '700',
        cursor: 'pointer',
        border: 'none',
        textAlign: 'center',
    },
};

export default Report;
