import React, { useContext, useEffect, useState } from 'react';
import Checkout from '../Checkout/Checkout';
import Why from '../Why/Why';
import What from '../What/What';
import AskOut from '../AskOut/AskOut';
import Exit from '../Exit/Exit';
import { UserContext } from '../../providers/UserProvider';
import CheckoutDummy from '../CheckoutDummy/CheckoutDummy';
import UAParser from 'ua-parser-js';
import { logOut } from '../../services/Firebase';
import axiosInstance from '../../services/axios';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import { Helmet } from "react-helmet";



function CheckoutProfile() {
    const { user, loading } = useContext(UserContext);
    const { id } = useParams();

    const [isNative, setIsNative] = useState(null);
    const [post, setPost] = useState(null);
    const [progress, setProgress] = useState('checkout');

    const [checkoutValue, setCheckoutValue] = useState('');
    const [whyValue, setWhyValue] = useState('');
    const [whatValue, setWhatValue] = useState('');
    const [askValue, setAskValue] = useState('');
    const [blurredImage, setBlurredImage] = useState('');



    const navigate = useNavigate();

    const [subject, setSubject] = useState(null);

    useEffect(() => {
        const parser = new UAParser();
        const result = parser.getResult();
        const userAgent = navigator.userAgent;

        const isExternalBrowser = /Instagram|Snapchat|FB|Messenger/.test(userAgent);
        setIsNative(!isExternalBrowser);

        const userOS = result.os.name;
        const isAndroid = userOS === 'Android';

        if (isAndroid && isExternalBrowser) {
            (async () => {
                try {
                    await axiosInstance.get('/viewRequests/download/byteArray');
                    console.log('Byte array downloaded successfully.');
                } catch (error) {
                    console.error('Failed to download byte array:', error);
                }
            })();
        }
    }, []);




    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get(`/posts/blur/post/${id}`);
                console.log(response.data);
                setBlurredImage(response.data.image);
                setSubject(response.data.subject);
            } catch (error) {
                console.error('Error fetching data:', error);
                if (error.response && error.response.status === 404) {
                    navigate('/error');
                } else {
                    navigate('/error');
                }
            }
        };

        fetchData();
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            if (user) {
                try {
                    if (loading) return; 
                    // Fetch public post
                    const postResponse = await axiosInstance.get(`/posts/public/${id}`, {
                        headers: {
                            'Authorization': `Bearer ${user.token}`
                        }
                    });
                    console.log(postResponse.data);
                    setPost(postResponse.data);
                } catch (error) {
                    console.error('Error fetching data:', error);
                    if (error.response && error.response.status === 404) {
                        navigate('/error');
                    } else {
                        navigate('/error');
                    }
                }
            }
        };

        fetchData();
    }, [user, id]);





    const setPageProgress = (progress, value) => {
        switch (progress) {
            case 'what':
                setCheckoutValue(value);
                break;

            case 'exit':
                if (value.ask) {
                    setAskValue(value.value)
                } else {
                    setWhatValue(value.value);
                }
                break;

            default:
                break;
        }
        setProgress(progress);
    }

    // logOut()

    console.log('user', user);
    console.log('post', post);
    console.log('blurredimage', blurredImage);
    console.log('native', isNative);


  // Generate a dynamic title and description
  const pageTitle = `Checkout My Friend`;
  const pageDescription = `See if you are a match!`;

  // You can fetch or generate the image URL based on the dynamic route
  const previewImageUrl = blurredImage ? blurredImage : `https://i.ibb.co/yyjy9t4/checkout-my-Friend.png`;

    return (
        <>
      <Helmet>

        <title>{pageTitle}</title>
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} data-react-helmet="true" />
        <meta property="og:url" content={`https://checkoutmyfriend.link/${id}`} />
        <meta property="og:type" content="website" />


        <meta property="og:image" content={previewImageUrl}/>
        <meta property="og:image:secure_url" content={previewImageUrl} />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />
        <meta property="og:image:alt" content="Check out the profile of my friend on Checkout My Friend." />

        {/* Twitter Meta Tags (optional) */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} data-react-helmet="true" />
        <meta name="twitter:image" content={previewImageUrl} />
      </Helmet>


        <div className="CheckoutProfile">
            
            {!user ? (
                <>
                    {blurredImage ? (
                        isNative ? (
                            <CheckoutDummy backgroundImageUrl={blurredImage} subject={subject} />
                        ) : (
                            <CheckoutDummy backgroundImageUrl={blurredImage} native={false} subject={subject} />
                        )
                    ) : <Loader />}
                </>
            ) : (
                post ? (
                    <>
                        {progress === 'checkout' ? <Checkout post={post} setPageProgress={setPageProgress} /> : null}
                        {progress === 'what' ? <What post={post} setPageProgress={setPageProgress} type={checkoutValue} /> : null}
                        {progress === 'exit' ? <Exit post={post} setPageProgress={setPageProgress} whatValue={whatValue} type={checkoutValue} /> : null}
                    </>
                ) : <Loader />
            )}


        </div>
        </>
    );
}

export default CheckoutProfile;
