import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Checkbox, CircularProgress } from '@mui/material';
import Entypo from '@mui/icons-material/ChevronLeft';
import axiosInstance from '../../services/axios';
import { UserContext } from '../../providers/UserProvider';
import LoginUserPage from '../LoginUserPage/LoginUserPage';
import GenericLoadingModal from '../../components/GenericModal/GenericModal';

const DeleteAccount = () => {
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingModalVisible, setIsLoadingModalVisible] = useState(false);
    const [success, setSuccess] = useState('');
    const { user } = useContext(UserContext);

    useEffect(() => {
        setIsLoadingModalVisible(isLoading);
    }, [isLoading]);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const onDelete = async () => {
        try {
            setIsLoading(true);
            const response = await axiosInstance.post('/request/web', { data: 'delete', type: 'delete' }, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });
            console.log(response.data);
            navigate('/');

        } catch (error) {
            setSuccess(`The request could not be completed at this time. This may be due to the absence of an account associated with the currently signed-in email, a ban from the platform, or another issue. You can try again later or reach out to us at help@checkoutmyfriend.link for assistance.`);
            console.error('error', error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const onCancel = () => {
        navigate(-1);
    };

    return (
        <>
        {user ? (
        <div style={{ backgroundColor: '#000', minHeight: '100vh', padding: '10px', color: 'white' }}>
        <div style={styles.header}>
            <button style={styles.closeButton} onClick={() => navigate(-1)}>
                <Entypo style={{ fontSize: '40px', color: 'white' }} />
            </button>
            <h1 style={styles.headerText}>Delete Account</h1>
        </div>

       {success ? (
          <div style={{ padding: '20px', marginTop: '20px', marginBottom: '50px' }}>
          <p style={{ color: '#A0A0A0', lineHeight: '25px', fontSize: '15px' }}>
              {success} 
          </p>
      </div>
       ) : ( <div style={{ padding: '20px', marginTop: '15px', marginBottom: '50px' }}>
            <p>Once you confirm, your account will be deactivated immediately, and you will lose access to all your data in the app.</p>

            <p style={{ marginTop: '20px' }}>Although your account will be inactive, your data will remain on our servers for a 30-day retention period to meet legal, security, and dispute resolution requirements. During this time, you cannot reactivate your account. After the 30-day period, all your data will be permanently deleted, in compliance with privacy regulations.</p>

            <h2 style={styles.warningText}>Would you still like to <span style={styles.highlightedText}>delete</span> your account?</h2>

            <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px' }}>
                <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    style={{ color: '#FEE500' }}
                />
                <span style={{ lineHeight: '30px', fontSize: '12px' }}>I understand & agree to the deletion policy.</span>
            </div>

            <button
                style={{ ...styles.confirmButton, backgroundColor: isChecked ? '#FF3148' : '#2D1717' }}
                onClick={onDelete}
                disabled={isLoading || !isChecked}
            >
                {isLoading ? <CircularProgress size={24} style={{ color: '#F6213A' }} /> : 'Confirm Deletion'}
            </button>

            <button style={styles.cancelButton} onClick={onCancel} disabled={isLoading}>
                Cancel Deletion
            </button>
        </div>)}

        <GenericLoadingModal isVisible={isLoadingModalVisible} onClose={() => setIsLoadingModalVisible(false)} />
       </div>) : (<LoginUserPage />)}
       </>
    );
};

const styles = {
    header: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '5px',
    },
    closeButton: {
        padding: '10px',
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
    },
    headerText: {
        color: '#FFFFFF',
        fontSize: '2rem',
        fontWeight: '900',
    },
    warningText: {
        color: 'white',
        lineHeight: '30px',
        fontSize: '1.5rem',
        marginTop: '20px',
        fontWeight: '900',
    },
    highlightedText: {
        color: '#FF3148',
    },
    confirmButton: {
        width: '97%',
        padding: '15px',
        marginTop: '15px',
        borderRadius: '50px',
        color: 'white',
        fontSize: '16px',
        fontWeight: '500',
        cursor: 'pointer',
        border: 'none',
    },
    cancelButton: {
        width: '97%',
        padding: '15px',
        marginTop: '15px',
        borderRadius: '50px',
        color: '#000',
        backgroundColor: '#FFF',
        fontSize: '16px',
        fontWeight: '500',
        cursor: 'pointer',
        border: 'none',
    },
};

export default DeleteAccount;
