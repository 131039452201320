import React, { useState } from 'react'
import './Header.css'
import { pageTitles } from '../../data/DummyValues'


const Header = ({ page }) => {
    const [headerImage, setHeaderImage] = useState(pageTitles[page]);

    return (
        <div className='header'>

            {/* <img src={headerImage} className='header-image' /> */}
            <p style={{color:'white', fontSize: 30, fontWeight: '700', fontStyle: 'italic'}}>Pick a message</p>

        </div>
    )
}

export default Header