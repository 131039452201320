import React from 'react'
import BackgroundImage from '../../components/BackgroundImage/BackgroundImage'
import { signInWithGoogle } from '../../services/Firebase'
import { useNavigate } from 'react-router-dom'
import { backgrounds } from '../../data/backgrounds'
import { iconsData } from '../../data/icons'
import logo from '../../assets/logos/off-logo.png';
import cmftext from '../../assets/cmf-text-full.png';


const LoginUserPage = () => {

    const handleClick = () => {
        signInWithGoogle();
    }

    const navigate = useNavigate();


    


    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', height: '100vh', backgroundColor: 'black' }}>

                    <img src={logo} style={{width:100, height: 100, alignSelf:'center'}} />

                    <img src={cmftext} style={{width: '70%', alignSelf: 'center'}} />
                
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px', margin: '5px 0px' , flexDirection: 'column'}}>
                        <button className="exit-button" onClick={handleClick} style={{backgroundImage: `url(${iconsData['yellowBtn']})`, backgroundSize: 'cover', backgroundPosition: 'center',backgroundRepeat: 'no-repeat', backgroundColor: '#FFE500', fontSize: '1.3em', fontWeight: '700'}}>
                           Sign In
                        </button>

                        <p style={{textAlign: 'center', color: 'white'}}>Please sign in to confirm your identity before proceeding with the request.</p>
                    </div>


                    <div style={{ display: 'flex',width: '100vw', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', }}>
                        <p style={{color: 'white', marginRight: 20, fontWeight:  '900' }} onClick={() => navigate('/tos')}>Terms</p>
                        <p style={{color: 'white', fontWeight: '900'}} onClick={() => navigate('/privacy')}>Privacy</p>
                    </div>
               
        </div>
    )
}

export default LoginUserPage